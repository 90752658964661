import { defineStore } from "pinia";
import { type Comment } from "~/types/Comment";
import { type Hotel} from "~/types/Hotel";
interface HotelState {
  hotel: Hotel | null;
  comment: Comment | null;
  edit_comment_modal: boolean;
  delete_comment_modal: boolean;
  report_comment_modal: boolean;
}
export const useHotelStore = defineStore("hotel", {
  state: (): HotelState => ({
    hotel: null,
    comment: null,
    edit_comment_modal: false,
    delete_comment_modal:false,
    report_comment_modal:false,
  }),
  getters: {},
  actions: {},
});
